<template>
  <div class="full-container">
    <div class="panel-1">
      <img class="banner" :src="banner" alt="" />
      <div class="resource">
        <h1>拥有海量机械资源</h1>
        <div class="flex">
          <div class="num">
            <div>{{ topData.vehicleNumber }}<span>&nbsp; 台</span></div>
            <div class="text">机械数量</div>
          </div>
          <div class="num">
            <div>{{ topData.cityNumber }}<span>&nbsp; 座</span></div>
            <div class="text">机械分布省市</div>
          </div>
          <div class="num">
            <div>{{ topData.companyNumber }}<span>&nbsp; 家</span></div>
            <div class="text">设备供应商</div>
          </div>
        </div>
        <div class="selsct_box">
          <el-cascader
            :options="carStatusList"
            @change="carStatusChange($event)"
            v-model="ruleForm.vehicleType"
            :props="optionProps"
            size="small"
            ref="vehicleTypeName"
            placeholder="请选择设备类型"
            style="width: 15%"
          >
          </el-cascader>
          <el-select
            size="small"
            v-model="ruleForm.vehicleBrandId"
            placeholder="请选择品牌"
            @change="changeBrandId"
            style="width: 15%"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-date-picker
            size="small"
            value-format=yyyy/MM/dd
            v-model="ruleForm.workTimeStart"
            type="date"
            placeholder="请选择进场时间"
            style="width: 15%"
          ></el-date-picker>
          <el-select
            size="small"
            v-model="ruleForm.leaseType"
            placeholder="请选择租赁方式"
            @change="changeLeaseType($event)"
            style="width: 16%"
          >
            <el-option
              v-for="v in leaseTypeList"
              :key="v.id"
              :label="v.name"
              :value="v.id"
            >
            </el-option>
          </el-select>
          <el-button size="small" class="btn" @click="submit"
            >现在预订</el-button
          >
        </div>
      </div>
      <div class="fenlei">
        <div class="container">
          <div>
            <h1>热门租赁</h1>
            <div class="line"></div>
          </div>
          <div class="list">
            <div class="IconLeft">
              <router-link to="result" class="Mechanics16">
                <div class="Mechanics16Name">其它机械</div>
                <img style="object-fit: contain;" src="../../assets/images/bg-5.png" alt="" />
              </router-link>
              <div class="right_box">
                <div class="IconRight">
                  <div
                    class="left_item"
                    style="margin-right: 20px"
                    v-if="classifies[0]"
                    @click="toResult(classifies[0].vehicleTypeId)"
                  >
                    <div class="Mechanics16Name">
                      {{ classifies[0].vehicleTypeName }}
                    </div>
                    <img :src="classifies[0].typeImg" alt="" />
                  </div>
                  <div
                    class="right_item"
                    v-if="classifies[1]"
                    @click="toResult(classifies[1].vehicleTypeId)"
                  >
                    <div class="Mechanics16Name">
                      {{ classifies[1].vehicleTypeName }}
                    </div>
                    <img :src="classifies[1].typeImg" alt="" />
                  </div>
                </div>
                <div class="IconRight">
                  <div
                    class="right_item"
                    style="margin-right: 20px"
                    v-if="classifies[2]"
                    @click="toResult(classifies[2].vehicleTypeId)"
                  >
                    <div class="Mechanics16Name">
                      {{ classifies[2].vehicleTypeName }}
                    </div>
                    <img :src="classifies[2].typeImg" alt="" />
                  </div>
                  <div
                    class="left_item"
                    v-if="classifies[3]"
                    @click="toResult(classifies[3].vehicleTypeId)"
                  >
                    <div class="Mechanics16Name">
                      {{ classifies[3].vehicleTypeName }}
                    </div>
                    <img :src="classifies[3].typeImg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomList">
            <div
              v-for="(item, index) in classifies.slice(4, 8)"
              :key="index"
              class="item"
              @click="toResult(item.vehicleTypeId)"
            >
              <div class="Mechanics16Name">
                {{ item.vehicleTypeName }}
              </div>
              <img :src="item.typeImg" alt="" />
            </div>
          </div>
          <router-link to="result">
            <div class="btns" style="">查看更多</div>
          </router-link>
        </div>
      </div>
      <div class="pingtai">
        <img class="banner" :src="banner_2" alt="" />
        <div class="container">
          <div>
            <h1>平台介绍</h1>
            <div class="line"></div>
          </div>
          <div class="bottomList">
            <div v-for="(item, index) in pingtaiList" :key="index" class="item">
              <img :src="item.img" alt="" />
              <div class="Mechanics16Name">
                {{ item.name }}
              </div>
              <div class="texts">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hangyejiesao">
        <div class="container">
          <div>
            <h1>行业动态</h1>
            <div class="line"></div>
          </div>
          <el-row>
            <el-col :span="12">
              <img style="object-fit: contain;" :src="closeImg" alt="" />
            </el-col>
            <el-col :span="12">
              <div class="list">
                <router-link :to="'/consultDetail?id='+item.noticeId" class="item" v-for="(item, index) in notices" :key="index">
                  <div>
                    {{ item.noticeTitle }}
                  </div>
                  <div>
                    {{ item.createTime }}
                  </div>
                </router-link>
              </div>
            </el-col>
          </el-row>
          <div class="more">
            <router-link to="/consult"> 查看更多资讯</router-link>
          </div>
        </div>
      </div>
      <div class="hangyejiesao">
        <div class="container">
          <div>
            <h1>合作企业</h1>
            <div class="line"></div>
          </div>
          <div class="hezuo">
            <div class="item" v-for="(item, index) in brands" :key="index">
              <div>
                <img style="object-fit: contain;" :src="item.brandImg" alt="" />
              </div>
              <div class="text">
                {{ item.brandName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>

    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'

import { vehicleTypeTree, getBrand } from '@/api/user'
import { getAllData } from '@/api/login'

export default {
  name: 'Home',
  data() {
    return {
      bg3: require('@/assets/images/logo-bg.png'),
      banner: require('@/assets/images/big-bg.png'),
      banner_2: require('@/assets/images/logo-bg2.png'),
      isActive: true,
      carStatusList: [],
      brandList: [],
      leaseTypeList: [
        { id: '0', name: '小时' },
        { id: '1', name: '日' },
        { id: '2', name: '月' },
        { id: '3', name: '工作量' },
      ],
      optionProps: {
        value: 'vehicleTypeId',
        label: 'vehicleTypeName',
      },
      ruleForm: {
        vehicleName: '',
        vehicleModel: '',
        vehicleBrand: '',
        firstLevelType: '',
        firstLevelTypeId: '',
        secondLevelType: '',
        secondLevelTypeId: '',
        vehicleBrandId: null,
        leaseType: '',
        leaseWork: null,
        leaseUnit: '',
        leaseTypeCode: '',
        insure: '',
        leaseTimeStart: '',
        leaseTimeEnd: '',
        workTimeStart: '',
        vechileNumber: null,
        vehicleOffer: null,
      },
      classifies: [],
      pingtaiList: [
        {
          name: '7*12小时',
          img: require('@/assets/images/t1.png'),
          text: '官方客服，全年无休',
        },
        {
          name: '公司分配',
          img: require('@/assets/images/t2.png'),
          text:
            '分公司遍布杭州、湖南、广州、江西、深圳等地，售后服务响应更快，服务质量更优',
        },
        {
          name: '专业顾问',
          img: require('@/assets/images/t5.png'),
          text: '数量庞大的专业税务咨询师团队，服务流程标准化、规范化',
        },
        {
          name: '服务灵活',
          img: require('@/assets/images/t3.png'),
          text:
            '商务专员和客服专员以电话、微信、现场等多样化形式向客 户提供服务，更快捷响应客户需求',
        },
        {
          name: '资金安全',
          img: require('@/assets/images/t6.png'),
          text:
            '稳定安全的银行存管系统；每日五百万笔交易处理；实时到账、实时查询',
        },
        {
          name: '技术平台',
          img: require('@/assets/images/t4.png'),
          text:
            '独立自主研发云结算系统，可实现自动化的佣金计算、下发、对账、统计',
        },
      ],
      notices: [],
      brands: [],
      topData: {},
      closeImg: '',
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('userInfo'))
    },
  },
  created() {
    getAllData().then((res) => {
      this.classifies = res.data.classifies
      this.notices = res.data.notices
      this.brands = res.data.brands
      this.closeImg = res.data.ads[0].closeImg
      this.topData = res.data.index
    })
    vehicleTypeTree().then((res) => {
      this.carStatusList = res.data
    })
    getBrand().then((res) => {
      this.brandList = res.data
    })
  },
  components: { elFooter },
  methods: {
    carStatusChange(e) {
      var name1 = ''
      this.carStatusList.forEach((item) => {
        if (item.vehicleTypeId == e[0]) {
          name1 = item.vehicleTypeName
          if (e.length == 2 && item.children != null) {
            item.children.forEach((v) => {
              if (v.vehicleTypeId == e[1]) {
                name1 = item.vehicleTypeName + '/' + v.vehicleTypeName
              }
            })
          }
        }
      })
      this.ruleForm.firstLevelType = name1.split('/')[0]
      this.ruleForm.secondLevelType = name1.split('/')[1]
      this.ruleForm.firstLevelTypeId = e[0]
      this.ruleForm.secondLevelTypeId = e[1]
    },
    changeBrandId(e) {
      var objVal = {}
      this.brandList.forEach((val) => {
        if (val.id == e) {
          objVal = val
        }
      })
      this.ruleForm.vehicleBrandId = objVal.id
      this.ruleForm.vehicleBrand = objVal.brandName
    },
    changeLeaseType(e) {
      let newObj = {}
      newObj = this.leaseTypeList.find((item) => {
        return item.id === e
      })
      this.ruleForm.leaseType = newObj.name
      this.ruleForm.leaseTypeCode = newObj.id
      if (e != 3) {
        this.ruleForm.leaseUnit = newObj.name
      } else {
        this.ruleForm.leaseUnit = ''
      }
    },
    submit() {
      if (this.userInfo != null && this.userInfo.companyStatus == 2) {
        this.$router.push({
          path: '/addNeed',
          query: {
            ruleForm: JSON.stringify(this.ruleForm),
            type: 3,
          },
        })
      } else {
        if (this.userInfo == null) {
          this.$message.warning('请先登录')
        } else {
          this.$message.warning('该功能仅限企业使用')
        }
      }
    },
    toResult(id) {
      this.$router.push({
        path: 'result',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="stylus">
@import '~@/styles/home.styl'
</style>
<style lang="scss" scoped>
.selsct_box {
  margin-top: 50px;
  display: flex;
}
.btn {
  border: none;
  background-color: #fcb110;
  color: #fff;
}
</style>
